.no-js {
    .archived-reports__switcher {
        display: none;
    }
}
.js {
    .js-hidden {
        display: none;
    }
}
