/*
    Component: News
    ---
*/

.more-news {
    background-color: #f5f5f5;
    padding: 3em 1em 1em;
    margin-top: 4em;

    @include media-query("lap-and-up") {
        padding: 5em 3em 3em;
    }

    &__title {
        margin-bottom: 2em;
        text-align: center;
    }

    &__archive-link {
        padding-top: 1em;
        padding-bottom: 1em;
        margin-bottom: -4em; // Compensate footer
        text-align: center;
        color: #9e9e9e;
        background-color: #efefef;
        display: block;
        text-transform: uppercase;
        transition: color 200ms;

        &:hover {
            color: #222;
        }
    }

    .no-js &__section {
        display: none;
    }
}
