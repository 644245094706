/*------------------------------------*\
    # Fairs template
\*------------------------------------*/

.fair {
    &__wrapper {
        @include media-query(desk) {
            &:nth-of-type(1) .fair__item, &:nth-of-type(2) .fair__item {
                border-top: none;
            }
        }
    }
    &__item {
        margin-bottom: 1.5em;
        padding-top: 1.5em;
        border-top: 1px solid $grey-04;


    }
    &__title {
        margin-top: 0;
    }
    &__target {
        font-size: .5em;
        display: inline-block;
        background-color: grey;
        color: #fff;
        padding: .2em .3em;
        margin-left: 1em;
        position: relative;
        top: -4px;
    }
    &__meta {
        margin-bottom: 1em;
    }
    &__big-date {
        text-align: center;
        font-size: 1.1em;
        border: 1px solid $alpha-ui-color;
        width: 4em;
    }
    &__big-date-item {
        padding: .2em;
        color: $alpha-text-color-dark;
    }
    &__big-date-month {
        color: #fff;
        text-transform: uppercase;
        background-color: $alpha-ui-color;
    }
}
