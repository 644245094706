/*------------------------------------*\
    # sioen square
\*------------------------------------*/

.sioen-square {
    //display: inline-block;
    margin: 0 0 .2rem 0;
    padding: .5rem;
    display: block;
    background-color: $beta-text-color;
    color: #FFF;
    font-size: 1.2em;
    position: relative;
    min-height: 44px;

    @include media-query(lap-and-up) {
        padding: 0;
        margin-right: .2rem;
        float: left;
        //width: 9em;
        width: calc(25% - .2rem);
        height: 5em;
    }
    @include media-query(desk) {
        height: 9em;
    }

    a {
        display: block;
        height: 100%;
        color: #FFF;
        transition: background-color 150ms ease-in-out;

        &:hover {
            background-color: $alpha-ui-color;
        }
    }

    &__inner {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include media-query(lap-and-up) {
            right: 1rem;
            bottom: 1rem;
            left: 1rem;
            transform: none; /* Undo */
            top: auto; /* Undo */
        }
    }
}
