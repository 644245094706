/*------------------------------------*\
    # Sidebar template
\*------------------------------------*/

.sidebar {
    &__block {
        border-bottom: 1px solid lighten($alpha-ui-color, 35%);
        background-color: #f5f5f5;
        padding: .7em 1em;
        font-size: .9em;
        margin-bottom: 2em;
    }

    &__title {
        font-weight: 500;
        color:  $alpha-text-color;
        text-transform: none;
    }

    &__nav {
        margin-bottom: 1em;

        &-link {
            padding: .25em .3em;
            border-bottom: 1px solid #E6E6E6;
            display: block;

            &.active {
                font-weight: bold;
            }
        }
    }
}
