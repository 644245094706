/*------------------------------------*\
    # Footer template
\*------------------------------------*/

.footer {
    font-size: 90%;
    margin-top: 3rem;
    background-color: #f5f5f5;
    padding: 2rem 0 2rem;
    border-bottom: 8px solid $alpha-ui-color;

    @include media-query(lap-and-up){
        margin-top: 6rem;
    }

    &__wrap {
        @extend .page__wrap;
        @extend %u-clearfix;
        display: flex;
    }

    &__title {
        border-bottom: 1px dotted $alpha-ui-color;
    }

    &__info {
        float: left;
        padding-right: 2em;
        border-right: 1px solid #D8D8D8;
    }

    &__about {
        display: none;
        @include media-query(lap-and-up){
            display: block;
        }
    }

    &__legal {
        min-width: 12rem;
        font-size: .85rem;
        align-self: flex-end;
    }

    &__social {
        &:before {
            @include media-query(lap-and-up){
                display: block;
                content: '';
                background: #D8D8D8;
                height: 1px;
                width: 5rem;
                margin-top: 3rem;
                margin-bottom: 1.2rem;
            }
        }

        .social-btn {
            background-color: #B5B5B5;
        }
    }
}
