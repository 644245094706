/*
    Component: News article
    ---
*/

.news-article {
    &__title {
        margin-top: 0;
        margin-bottom: 1.2em;
        text-align: left;
        color: $alpha-ui-color;
    }

    &__meta {
        margin-top: 4rem;
        font-size: 80%;
        color: $grey-03;
        text-transform: uppercase;
    }

    &__image {
        margin-bottom: 1.5em;
    }

    &__body {
        margin-bottom: 5em;

        figure {
            position: relative;
        }

        figcaption {
            @include media-query("large-desk") {
                background-color: transparent;
                text-align: left;
                position: absolute;
                top: 0;
                left: 103%;
                width: 10em;
            }
        }

        blockquote {
            padding: 2em 3em;
            background-color: #f5f5f5;
            font-style: italic;

            p:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__social-sharing {
        margin-bottom: 1.5em;
    }

    &-teaser {

        &__item {
            margin-bottom: 4em;
        }

        &__thumbnail {
            display: block;
            margin-bottom: 1em;
            border-bottom: 4px solid $alpha-ui-color;
        }

        &__title {
            margin: 0;
            display: inline-block;
            line-height: 1;
            font-size: 1.3em;
            text-transform: none;

            a {
                color: $alpha-text-color;
            }
        }

        &__meta {
            font-size: 80%;
            color: $grey-03;
            margin-bottom: 1em;
        }

        &__readmore {
            display: inline-block;
            color: $alpha-ui-color;
            margin-top: .75em;
            transition: color 250ms;

            &:hover {
                color: $alpha-ui-color;
            }
        }
    }
}