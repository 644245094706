/*------------------------------------*\
    $PAGINATION
    twitter bootstrap: https://github.com/twitter/bootstrap/blob/master/less/pagination.less
\*------------------------------------*/
$paginationBorder: #DDD;
$paginationBackground: #FFF;
$paginationActiveBackground: #f5f5f5;
$grayLight: #999;

// Space out pagination from surrounding content
.o-pagination {
    margin: $base-line-height 0;

    &__item {
        display: inline-block;
        padding: 4px 12px;
        text-decoration: none;
        color: $alpha-text-color;
        //background-color: $alpha-text-color;
        //border: 1px solid $paginationBorder;

        &--active {
            color: #fff;
            background-color: $alpha-text-color-light;
            //border: 1px solid $paginationBorder;
        }
        &:hover {
            background-color: #f5f5f5;
        }
    }

    &--centered {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
}
