/*------------------------------------*\
    # Homepage template
\*------------------------------------*/

.hero {
    margin-bottom: 3em;
    margin-top: 3em;
    font-size: 2em;
    text-align: center;

    p {
        margin-bottom: 0;
    }
}

.sioen-division {
    &__item {
        margin-bottom: 1rem;
        overflow: hidden;
        position: relative;
        display: block;
        height: 50vh;

        .infotext {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 1.4rem;
            text-transform: uppercase;

            span {
                background-color: $alpha-ui-color;
                padding: .1rem .3rem;
                white-space: nowrap;
            }
        }
    }
    &__picture {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 100%;
        transition: all .1s linear;

        &:hover {
            -webkit-backface-visibility: hidden;
            transform: scale(1.1,1.1);
        }

        &--coating {
            background-image: url("/content/home/coating.jpg");
        }
        &--apparel {
            background-image: url("/content/home/apparel.jpg");
        }
        &--chemicals {
            background-image: url("/content/home/chemicals.jpg");
        }
    }
}

.home {
    &__dividertitle {
        font-size: 1.7em;
        text-align: center;
        position: relative;
        z-index: 1;
        margin-bottom: 1.5em;

        &:before {
            border-top: 1px solid #d8d8d8;
            content:"";
            margin: 0 auto; /* this centers the line to the full width specified */
            position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
            top: 50%; left: 0; right: 0; bottom: 0;
            z-index: -1;
        }

        span {
            /* to hide the lines from behind the text, you have to set the background color the same as the container */
            background: #fff;
            padding: 0 15px;
        }
    }

    &__news {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    &__newsletter {
        text-align: center;
        margin: 0 auto;
        max-width: 40em;

        &-wrap {
            background-color: $grey-05;
            padding: 3em 1em 4em;
            margin: 4em 0 -2em;
        }
    }
}
