/*
    Components: page-header
    ---
*/

.page-header {
    background-color: #f5f5f5;
    margin-top: -2rem;
    margin-bottom: 2rem;
    overflow: hidden;


    &__wrap {
        @extend .page__wrap;
        position: relative;
    }

    &__text {
        color: #F5F5F5;
        background-color: rgba(16,150,207,.85);
        font-size: 1.2em;
        padding: 1rem;
        /*width: calc(100% - 2rem);*/

        @include media-query(tablet) {
            font-size: 1.6em;
            position: absolute;
            bottom: 0;
            width: calc(100% - 2rem);
        }
        @include media-query(lap-and-up) {
            position: absolute;
            top: 0;
            left: .5em;
            padding: 3.5rem 0 0 4rem;
            height: 100%;
            width: 45%;
            //font-size: 1.6em;
            font-size: 3.2vw;
        }
        @include media-query(desk) {
            font-size: 2em;
        }
    }
}
