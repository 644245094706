/*
    Component: News article
    ---
*/

.c-wrapper--narrow-content {
    max-width: 53rem;
    margin-left: auto;
    margin-right: auto;
}

.news-meta {
    &__container {
        border: 2px solid #ADADAD;
        padding: 1.75em 2em .5em;

        font-family: arial,sans-serif;
    }

    &__title {
        color: #1a0dab;
        font-size: 18px;
    }
    &__url {
        color: #006621;
        font-size: 14px;
    }
    &__description {
        color: #545454;
        font-size: 13px;
    }

    &__warning {
        font-size: 14px;
    }
}