/*
    Components: Social networks buttons
    ---
    Dependencies: _components.icons
*/

$color-facebook: #3b579d;
$color-twitter: #4092cc;
$color-linkedin: #1f88be;
$color-googleplus: #d34836;

.social-btn {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    color: #FFF;
    border-radius: 10em;
    padding: .6em;
    line-height: 1;
    box-shadow: 0 0 0 0 rgba(39,41,43,.15) inset;
    text-align: center;

    .icon {
        width: 1em;
        margin: 0;
        opacity: .9;
        vertical-align: baseline;
        // undo default link style
        color: #FFF !important;
        text-decoration: none !important;
    }

    // Modifiers //

    &--facebook {
        background-color: $color-facebook;
        &:hover {
            background-color: lighten($color-facebook, 8%);
        }
    }
    &--twitter {
        background-color: $color-twitter;
        &:hover {
            background-color: lighten($color-twitter, 8%);;
        }
    }
    &--linkedin {
        background-color: $color-linkedin;
        &:hover {
            background-color: lighten($color-linkedin, 8%);;
        }
    }
    &--google-plus {
        background-color: $color-googleplus;
        &:hover {
            background-color: lighten($color-googleplus, 6%);;
        }
    }
}
