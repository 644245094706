/*------------------------------------*\
    # Site-header template
\*------------------------------------*/

.site-header {
    border-top: 8px solid $alpha-ui-color;
    margin-bottom: .4rem;

    @include media-query(lap-and-up) {
        margin-bottom: 2rem;
    }

    &__wrap {
        @extend .page__wrap;
        @extend %u-clearfix;
        //border-bottom: 1px solid #f5f5f5;
    }

    &__bar {
        background-color: #0c96d0;
        height: 46px;
        margin-left: -1rem;
        margin-right: -1rem;
        padding: 0 1rem;
        position: relative;

        @include media-query(lap-and-up){
            background-color: transparent;
            height: auto;
            margin: 0;
            padding: 0;
            display: inline;
            float: left;
        }
    }

    &__logo {
        width: 6rem;

        @include media-query(lap-and-up) {
            width: 8.5rem;
        }
        @include media-query(desk) {
            width: 10rem;
        }
    }

    .mini-header__button {
        position: absolute;
        top: 2px;
        display: block;
        width: 44px;
        height: 44px;
        text-align: center;
        white-space: nowrap;
        -webkit-font-smoothing: antialiased;
        line-height: 44px;

        @include media-query(lap-and-up) {
            display: none;
        }
    }

    // Extensions on that button //

    .hamburger {
        right: $base-spacing-unit / 2;

        // "Generate" hamburger icon with CSS box-shadow
        &::after {
            content: "";
            position: absolute;
            display: block;
            height: 0;
            top: 50%;
            margin-top: -0.55rem;
            left: 1rem;
            box-shadow: 0 0px 0 1px $white, 0 5px 0 1px $white, 0 10px 0 1px $white;
            width: 1rem;
        }
    }
}
