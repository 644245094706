/*------------------------------------*\
    # Reports page template
\*------------------------------------*/

.archived-report {
    &__set {
        margin-top: 1em;
    }
}

.recent-reports {
    margin-bottom: 6rem;
}

.report {
    &__item {
        display: block;
        margin-bottom: 1rem;
        background-color: #f5f5f5;
        padding: .3em;
        text-align: center;
        color: $alpha-text-color;
        transition: color 200ms;

        &:hover {
            color: $alpha-link-color;
        }
    }
    &__name {
        margin-top: .7em;
        font-size: .9em;
    }
    &__meta {
        margin: .3em 0;
        font-size: .7em;
        color: #a2a2a2;
    }
}
