/*------------------------------------*\
    # Page template
\*------------------------------------*/

.page {
    &__wrap {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 1em;
    }
    &__title {
        font-weight: 500;
        margin-bottom: 1em;
    }
}

.content-with-sidebar {
    .content {
        @include media-query(lap-and-up) {
            width: calc(100% - 300px);
            padding-right: 20px;
            float: left;
        }
        @include media-query(desk) {
            width: calc(100% - 330px);
            padding-right: 50px;
        }
    }
    .sidebar {
        margin-top: 2rem;

        @include media-query(lap-and-up) {
            margin-top: 0;
            width: 280px;
            float: right;
        }
    }
}

.page-teaser {
    &__item {
        margin-bottom: 1em;
        margin-top: 1em;
        padding-top: 1em;
        border-top: 1px solid #f1f1f1;
    }
    &__title {
        text-transform: none;
        color: $alpha-link-color;
    }
}
