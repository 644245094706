/*
    Components: legal-nav
    ---
*/

.legal-nav {
    margin-left: 2em;

    &__item {
        text-align: left;
        display: block;
        padding: .4em 0;
        border-bottom: 1px solid #E6E5E5;
        white-space: nowrap;

        &--copyright {
            margin-top: .5rem;
            border: none;
            color: #B5B5B5;
            padding-bottom: 0;
        }
    }
}
