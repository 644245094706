/* All fonts used in the website */

// eot for IE8
// woff2 for FF & Chrome
// woff for the rest

@font-face {
    font-family: 'sourcesans';
    src: url('../fonts/sourcesans/sourcesanspro-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/sourcesans/sourcesanspro-regular-webfont.woff2') format('woff2'),
         url('../fonts/sourcesans/sourcesanspro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sourcesans';
    src: url('../fonts/sourcesans/sourcesanspro-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/sourcesans/sourcesanspro-bold-webfont.woff2') format('woff2'),
         url('../fonts/sourcesans/sourcesanspro-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'sourcesans-light';
    src: url('../fonts/sourcesans/sourcesanspro-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/sourcesans/sourcesanspro-light-webfont.woff2') format('woff2'),
         url('../fonts/sourcesans/sourcesanspro-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* text */
//alpha-font-family: "sourcesans-light", Arial;
$alpha-font-family: "sourcesans", Arial;
$base-font-size: 18px;
