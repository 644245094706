/*------------------------------------*\
    # Primary navigation
\*------------------------------------*/

.primary-nav {
    font-size: 1.2em;
    // 1. Show/hide transition animation is done via max-height property
    max-height: 0; /* [1] */
    transition: .4s;
    // Mini nav grid
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: .5rem;

    @include media-query(lap-and-up) {
        float: right;
        margin-top: 0;
        clear: right; // Push primary-nav below the right floated language_nav
    }

    &.inactive {
        // 1. Undo wrapper padding to achieve "full-width" effect
        // 2. Hide borders van onderliggende elementen die "erdoor" komen als de navigatie 0 max-height heeft
        // margin-left: -$base-spacing-unit / 2; /* [1] */
        // margin-right: -$base-spacing-unit / 2; /* [1] */
        overflow: hidden; /* [2] */

        @include media-query(lap-and-up){
            max-height: 100px;
            background-color: transparent;
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.active {
        max-height: 1000px;
        padding-bottom: .5rem;
        border-bottom: 1px solid $alpha-ui-color;
        margin-bottom: .5rem;

        @include media-query(lap-and-up){
            padding-bottom: 0;
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    &__item {
        color: $alpha-text-color;
        width: 33.33333%;
        padding: .2em;
        // border-bottom: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        font-size: 1.3rem;

        &:nth-child(3n) {
            border-right: none;
        }
        &:nth-child(-n+3) {
            border-bottom: 1px solid #e6e6e6;
            @include media-query(lap-and-up) {
                border-bottom-color: transparent;
                border-bottom-width: .2rem;
            }
        }

        @include media-query(lap-and-up) {
            margin-left:1rem;
            display: inline-block;
            padding-top: .15rem;
            border: none;
            border-bottom: .2rem solid transparent;
            transition: color 150ms ease-in-out, border 120ms ease-in-out;
            width: auto;
        }

        @include media-query(desk) {
            padding-top: .45rem;
        }

        &.active {
            color: $alpha-ui-color;
            font-weight: 700;
            @include media-query(lap-and-up) {
                border-color: $alpha-ui-color;
            }
        }

        &:hover {
            @include media-query(lap-and-up) {
                color: $alpha-ui-color;
                border-color: $alpha-ui-color;
            }
        }
    }
}

.language-nav {
    margin-top: .5em;
    text-align: center;
    max-height: 0; /* [1] */
    transition: .4s;

    @include media-query(lap-and-up) {
        float: right;
        display: flex;
        padding: 0 0 .2em .4em;
        clear: right;
        font-size: .8em;
    }

    &.inactive {
        overflow: hidden;

        @include media-query(lap-and-up){
            max-height: 100px;
        }
    }
    &.active {
        max-height: 1000px;
    }

    &__link {
        //color: rgba(255, 255, 255, .65);
        color: $alpha-text-color-light;
        padding: 0 .2em;

        &:hover {
            color: $alpha-text-color;
        }

        &--active {
            color: $alpha-text-color;
        }
    }
}
