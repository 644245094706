/*
    Trumps: Shame
    ---
    Hacks
*/

.filter {
    @extend .o-box;
    margin-bottom: 1em;
    padding: 1rem;

    &__item {
        display: inline-block;
        background-color: $grey-04;
        color: $alpha-text-color;
        padding: .3em .5em;
        margin-right: .5em;

        &:hover {
            background-color: lighten($grey-04,3%);
        }

        &--active {
            background-color: $alpha-ui-color;
            color: white;

            &:hover {
                background-color: lighten($alpha-ui-color, 3%);
            }
        }
    }
}

.btn-fairs {
    float: right;
    color: #fff;
    background-color: $beta-text-color;

    &:hover {
        background-color: lighten($beta-text-color,3%);
    }

    @include media-query(palm) {
        float: none;
        width: 100%;
        margin-top: .5em;
    }
}

.o-grid__item {
    //See generic.page.scss
    font-size: 15px;

    @include media-query(tablet) {
        font-size: 16px;
    }

    @include media-query(lap-and-up) {
        font-size: $base-font-size;
    }
}

.js .js-hidden {
    display: none;
}

// Whitespace quirks on smaller breakpoints
html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}

#gmap {
    height: calc(100vh - 200px);
    min-height: 300px;

    @include media-query(tablet) {
        height: calc(100vh - 230px);
    }

    @include media-query(lap-and-up) {
        height: calc(100vh - 300px);
    }
}

.imagegrid {
    &__item {
        margin-bottom: 1.5em;
    }
    &__image {
        text-align: center;
        position: relative;
        margin-bottom: 1em;
        overflow: hidden;
    }
    &__tag {
        position: absolute;
        bottom: -1px;
        right: 0;
        padding: .5em;
        background-color: #1096cf;
        color: #fff;
    }
    &__title {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: .8em;
    }
    &__caption {
        //font-style: italic;
        @include media-query(lap-and-up) {
            font-size: .9em;
        }
    }
    &__external-link {
        margin-top: .8em;
    }
}

.brand {
    &__item {
        padding-bottom: 4em;
    }
    &__title {
        border-top: 1px solid $grey-05;
        padding-top: .3em;
    }
}

.cta-box {
    text-align: center;
    font-size: 1.5em;
    margin-top: 3em;

    &__text {
        margin-bottom: 1em;
    }
}

.euronext-live-info-list {
    dt {
        float: left;
        margin-right: .3em;

        &:after {
            content: ": ";
        }
    }
    dd {
        font-weight: bold;
    }
}

.contactpage {
    &__form {
        @include media-query(lap-and-up) {
            width: 70%;
            border-right: 1px solid $grey-04;
            padding-right: 4%;
        }
    }
    &__info {
        @include media-query(lap-and-up) {
            width: 30%;
            padding-left: 4%;
        }
    }
}

.certificates__wrapper, .brands__wrapper {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.csr-initiative {
    margin-bottom: 2em;

    @include media-query(lap-and-up) {
        margin-bottom: 5em;
    }

    &__image {
        display: none;

        @include media-query(lap-and-up) {
            width: 40%;
            display: block;
        }
    }
}

.manufacturing-plants {
    &__map {
        margin-bottom: 5em;

        @include media-query(palm) {
            display: none;
        }
    }
}
.manufacturing-plant {
    margin-bottom: 2em;
    padding-left: 1em;
    border-left: 5px solid $grey-05;

    &__address {
        margin-bottom: .5em;
    }
    &__extra {
        margin-top: .5em;
    }
}
