/*------------------------------------*\
    # Next/prev navigation (bottom)
\*------------------------------------*/

.nextprev {
    border-top: 1px solid #f1f1f1;
    margin-top: 4em;
    padding-top: 2em;

    &__item {
        padding: .6rem 1rem;
        border-width: 1px;
        border-style: solid;
        display: inline-block;

        @include media-query("palm") {
            display: block;
        }
    }

    &--prev {
        border-color: $alpha-ui-color;
        color: $alpha-ui-color;
        background-color: transparent;

        @include media-query(lap-and-up) {
            float: left;
        }

        &:hover {
            margin-left: -1px;
        }
    }

    &--next {
        background-color: $alpha-ui-color;
        border-color: $alpha-ui-color;
        color: $white;

        @include media-query(lap-and-up) {
            float: right;
        }

        &:hover {
            margin-right: -1px;
        }
    }
}
