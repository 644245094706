/*
    Components: Icons
    ---
    From: http://semantic-ui.com/elements/icon.html
*/

@font-face {
    font-family: 'Icons';
    src: url("../fonts/icons/icons.eot");
    src: url("../fonts/icons/icons.eot?#iefix") format('embedded-opentype'), url("../fonts/icons/icons.svg#icons") format('svg'), url("../fonts/icons/icons.woff") format('woff'), url("../fonts/icons.ttf") format('truetype');
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-transform: none;
}

/* 1. Refine font-rendering in webkit/firefox (fonts are rendered too bold) */
i.icon {
    display: inline-block;
    opacity: 1;
    margin: 0em 0.25rem 0em 0em;
    width: 1.23em;
    height: 0.9em;
    font-family: 'Icons';
    font-style: normal;
    line-height: 1;
    font-weight: normal;
    text-decoration: inherit;
    text-align: center;
    speak: none;
    font-smoothing: antialiased; /* [1] */
    -moz-osx-font-smoothing: grayscale; /* [1] */
    -webkit-font-smoothing: antialiased; /* [1] */
    backface-visibility: hidden;
}

i.icon:before {
    background: none !important;
}

i.icon--chevron-down:before {
    content: "\f078";
}
i.icon--chevron-up:before {
    content: "\f077";
}
i.icon--download:before {
    content: "\f019";
}
i.icon--mail:before {
    content: "\f0e0";
}
i.icon--marker:before {
    content: "\f041";
}
i.icon--facebook:before {
    content: "\f082";
}
i.icon--flickr:before {
    content: "\f16e";
}
i.icon--youtube:before {
    content: "\f166";
}
i.icon--twitter:before {
    content: "\f099";
}
i.icon--linkedin:before {
    content: "\f0e1";
}
i.icon--google-plus:before {
    content: "\f0d5";
}
i.icon--feed:before {
    content: "\f09e";
}
i.icon--pdf:before {
    content: "\f1c1";
}
i.icon--plus:before {
    content: "\f196";
}
i.icon--external:before {
    content: "\f08e";
}
i.icon--calendar:before {
    content: "\f133";
}
i.icon--calendar:before {
    content: "\f133";
}
i.icon--world:before {
    content: "\f0ac";
}
i.icon--arrow-right:before {
    content: "\f178";
}
i.icon--map:before {
    content: "\f278";
}
i.icon--info:before {
    content: "\f129";
}
