/*------------------------------------*\
    # Breadcrumbs
\*------------------------------------*/

.breadcrumb {
    font-size: .8rem;
    margin-top: -1rem; // Negate site-header margin
    padding: 0 .5rem .5rem;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 2rem;

    @include media-query(tablet) {
        margin-top: -.3rem; // Negate site-header margin
    }
    @include media-query(lap-and-up) {
        margin-top: -1.1rem; // Negate site-header margin
    }
    @include media-query(desk) {
        margin-top: -.5rem; // Negate site-header margin
    }

    &__item {
        @include media-query(palm) {
            display: none;

            &:nth-last-child(2) {
                display: inline-block;
                text-indent: -999em;
            }
        }
        &::after {
            content: "/";
            color: #aaa;
            margin: 0 .35rem;
            position: relative;
            //top: 1px;
        }
        &:last-child::after {
            content: "";
        }

        &:nth-last-child(2)::after {

            @include media-query(palm) {
                display: block;
                content: "←
                 Back";
                text-indent: 0;
                color: $alpha-link-color;
            }
        }

        &--active {
            font-weight: 500;
            color: #ADADAD;
        }
    }
}
